import styled from "@emotion/styled";
import { Box, Container, ContentContainer, Image, Text } from "@/components";
import { useTheme } from "@emotion/react";
import FindOutHowButton from "./FindOutHowButton";

const Background = styled(Container)(({ theme }) => ({
  alignItems: "flex-start",
  boxSizing: "border-box",

  overflow: "hidden",
  position: "relative",
  zIndex: 1,
  "&:before": {
    backgroundImage: "url(/assets/background/2.png)",
    backgroundPosition: "left -790px top 100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: 1130,
    bottom: 0,
    content: '""',
    left: 0,
    position: "absolute",
    opacity: 0.3,

    right: 0,
    top: 0,
  },
  [theme.breakpoints.tablet]: {
    "&:before": {
      backgroundSize: 1070,
      backgroundPosition: "left -590px top -40px",
    },

    paddingLeft: 45,
    paddingRight: 45,
  },
  [theme.breakpoints.desktop]: {
    "&:before": {
      backgroundPosition: "left -520px top 40px",
      backgroundSize: 1070,
    },
  },
  [theme.breakpoints.desktopXl]: {
    "&:before": {
      backgroundPosition: "left -520px top -100px",
      backgroundSize: 1123,
    },
  },
}));

const DesktopImage = styled(Image)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.desktop]: {
    display: "flex",
  },
}));

const MobileImage = styled(Image)(({ theme }) => ({
  maxWidth: 430,
  objectFit: "cover",
  objectPosition: "center",
  [theme.breakpoints.desktop]: {
    display: "none",
  },
}));

const BlockchainAdoptionSection = () => {
  const theme = useTheme();
  return (
    <Background>
      <ContentContainer
        alignSelf="center"
        flexDirection="column"
        gap={56}
        position="relative"
        paddingLeft={20}
        justifyContent="flex-start"
        paddingRight={20}
        zIndex={2}
        paddingTop={220}
      >
        <Container height="auto" flexDirection="column" gap={32}>
          <Text textAlign="center" maxWidth={720} variant="header1">
            Simplify Blockchain Adoption with Pangea Labs
          </Text>
          <Text
            color={theme.color.textSubtle}
            maxWidth={590}
            paddingLeft={15}
            paddingRight={15}
            textAlign="center"
            variant="body"
          >
            By integrating our proof-of-ownership technology into the top
            payment and commerce platforms we can create frictionless
            experiences native to these platforms that serve businesses and
            customers alike.
          </Text>
        </Container>
        <FindOutHowButton
          onClick={() =>
            document
              .getElementById("#signup-input-section")
              ?.scrollIntoView({
                block: "end",
                inline: "nearest",
                behavior: "smooth",
              })
          }
          marginBottom={180}
        />
        <DesktopImage src="/assets/coming-soon/2.png" />
        <MobileImage src="/assets/coming-soon/3.png" />
        <Box alignItems="center" flexDirection="column" gap={40}>
          <Image
            maxWidth={241}
            height="auto"
            src="/assets/logo/nota-wallet.png"
          />
          <Box alignItems="center" flexDirection="column" gap={16}>
            <Text textAlign="center" variant="header1">
              Seamless reward claim
            </Text>
            <Text
              maxWidth={560}
              textAlign="center"
              variant="body"
              color={theme.color.textSubtle}
            >
              Unlock the Benefits of Blockchain with Just a Phone Number and
              reach wide audience with a simple and intuitive rewards claim
              system
            </Text>
          </Box>
        </Box>
      </ContentContainer>
    </Background>
  );
};

export default BlockchainAdoptionSection;
