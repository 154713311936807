import { RouteObject } from "react-router-dom";
import { ComingSoon, Pricing } from "@/pages";

export enum URLS {
  DOCS = "https://eu-docs.qa.pangealabs.xyz/",
  HOME = "/home",
  INDEX = "/",
  NOTA_WALLET = "https://nota.pangealabs.xyz/",
  PRICING = "/pricing",
}

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <ComingSoon />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
];

/* For now Hide WIP pages
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <PageLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: URLS.HOME,
        element: <Home />,
      },
    ],
  },
];
*/
