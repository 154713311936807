const colors = {
  background: "#0B0B0F",

  black: "#000000",

  buttonDisabled: "grey",
  buttonGradient:
    "conic-gradient(from 3.63deg at 48.88% 50%, #8B7DFC -28.12deg, #92C0F7 44.14deg, #A96CFF 60deg, #92C0F7 217.75deg, #885CFF 241.88deg, #885CFF 256.72deg, #8B7DFC 331.88deg, #92C0F7 404.14deg)",
  buttonGradientOutlined:
    "conic-gradient(from 3.63deg at 48.88% 50%, #8B7DFC -28.12deg, #92C0F7 44.14deg, #A96CFF 60deg, #92C0F7 217.75deg, #885CFF 241.88deg, #885CFF 256.72deg, #8B7DFC 331.88deg, #92C0F7 404.14deg)",
  buttonPrimaryActive: "rgba(127, 17, 224, 0.9)",
  buttonPrimaryHover: "rgba(127, 17, 224, 0.8)",
  buttonStepBackground:
    "radial-gradient(111.14% 111.14% at 8.37% 0%, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%)",
  buttonStepHover: "background: rgba(26, 27, 35, 1)",
  buttonStepActive: "background: rgba(26, 27, 35, 0.9)",
  buttonTransparentActive: "rgba(255, 255, 255, 0.3)",
  buttonTransparentHover: "rgba(255, 255, 255, 0.1)",
  buttonBorderOverlay: "rgba(255, 255, 255, 0.4)",

  cardBackground: "#1A1B23",
  cardGradientBackground:
    "linear-gradient(0deg, rgba(166, 90, 240, 0.04), rgba(166, 90, 240, 0.04)), rgba(255, 255, 255, 0.08)",

  mobileMenuBackground: "rgba(33, 43, 54, 1)",

  primary: "#7C48DB",
  primaryHover: "rgba(33, 3, 217, 1)",

  text: "#FFFFFF",
  textCaption: "#B1B1B1",
  textPlaceholder: "#696C82",
  textSubtle: "#898CA9",

  transparent: "rgba(0, 0, 0, 0)",

  white: "#FFFFFF",
};

export default colors;
