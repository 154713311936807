import { Theme } from "@emotion/react";
import mq from "./breakpoints";
import colors from "./colors";
import typography from "./typography";

const theme: Theme = {
  breakpoints: {
    mobile: mq[0],
    tablet: mq[1],
    desktopS: mq[2],
    desktop: mq[3],
    desktopXl: mq[4],
    navbarPaddingBreakpoint: mq[5],
  },
  color: {
    background: colors.background,
    black: colors.black,
    buttonDisabled: colors.buttonDisabled,
    buttonBorderOverlay: colors.buttonBorderOverlay,
    buttonGradient: colors.buttonGradient,
    buttonGradientOutlined: colors.buttonGradientOutlined,
    buttonPrimaryActive: colors.buttonPrimaryActive,
    buttonPrimaryHover: colors.buttonPrimaryHover,
    buttonStepBackground: colors.buttonStepBackground,
    buttonStepBackgroundActive: colors.buttonStepActive,
    buttonStepBackgroundHover: colors.buttonStepHover,
    buttonTransparentActive: colors.buttonTransparentActive,
    buttonTransparentHover: colors.buttonTransparentHover,
    cardBackground: colors.cardBackground,
    cardGradientBackground: colors.cardGradientBackground,
    primary: colors.primary,
    primaryHover: colors.primaryHover,
    text: colors.text,
    textCaption: colors.textCaption,
    textPlaceholder: colors.textPlaceholder,
    textSubtle: colors.textSubtle,
    transparent: colors.transparent,
    white: colors.white,
  },
  typography: {
    body: typography.body,
    body1: typography.body1,
    body2: typography.body2,
    button: typography.button,
    header1: typography.header1,
    header2: typography.header2,
    header2Regular: typography.header2Regular,
    header3: typography.header3,
    header4: typography.header4,
    headerMobile1: typography.headerMobile1,
    headerMobile2: typography.headerMobile2,
    headerMobile3: typography.headerMobile3,
    headerMobile4: typography.headerMobile4,
    navItem: typography.navItem,
    navItemActive: typography.navItemActive,
    placeholder: typography.placeholder,
    subtitle1: typography.subtitle1,
    textInput: typography.textInput,
  },
};

export default theme;
