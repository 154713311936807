import { ComponentProps } from "../types";
import { Box } from "..";
import { useDrawer } from "@/utils/DrawerContext";
import { URLS } from "@/utils/routes";
import NavbarItem from "./NavbarItem";
import styled from "@emotion/styled";
import CTA from "./CTA";

const StyledMobileMenu = styled(Box)<ComponentProps>(({ theme }) => ({
  backgroundColor: "#212B36",
  width: "100%",
  position: "absolute",
  zIndex: 1,
  top: 0,
  transition: "all 0.15s linear",

  [theme.breakpoints.desktopS]: {
    height: "auto",
    display: "none",
  },
}));

interface MobileMenuProps extends ComponentProps {
  pathname: string;
}

const MobileMenu = ({ pathname }: MobileMenuProps) => {
  const { drawerOpen } = useDrawer();

  return (
    <StyledMobileMenu
      style={{
        transform: drawerOpen ? "translateY(0)" : "translateY(-100%)",
        opacity: drawerOpen ? 1 : 0,
      }}
    >
      <Box
        flexDirection="column"
        alignItems="flex-start"
        marginTop={100}
        paddingLeft={20}
        paddingRight={20}
      >
        <NavbarItem name="Home" path={URLS.INDEX} pathname={pathname} />
        <NavbarItem name="Pricing" path={URLS.PRICING} pathname={pathname} />
        <NavbarItem
          external
          name="Contact us"
          path={`mailto:${process.env.REACT_APP_PANGEA_CONTACT_EMAIL}`}
          pathname={pathname}
        />
        <CTA marginLeft={0} marginTop={16} marginBottom={20} />
      </Box>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
