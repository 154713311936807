import styled from "@emotion/styled";
import { extractCustomComponentProps } from "@/utils/component";
import { TextProps } from "../types";

const StyledText = styled("p")<TextProps>(
  ({ variant, theme }) =>
    ({ style, ...props }) => ({
      ...{
        ...theme.typography[variant],
        color: theme.color.text,
        margin: 0,
        padding: 0,
      },
      ...style,
      ...extractCustomComponentProps(props),
    })
);

const Text = ({
  variant = "body",
  ...props
}: TextProps & React.HTMLAttributes<HTMLParagraphElement>) => (
  <StyledText variant={variant} {...props} />
);

export default Text;
