import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import styled from "@emotion/styled";
import { Button, TextField } from "@/components";
import { validateEmail } from "@/utils/validation";
import { Container as BaseContainer } from "../Container";
import { TextFieldProps } from "../types";

const Container = styled(BaseContainer)(({ theme }) => ({
  flexDirection: "column",
  gap: 16,
  [theme.breakpoints.tablet]: {
    border: "1px solid",
    borderColor: theme.color.white,
    borderRadius: 32,
    boxSizing: "border-box",
    flexDirection: "row",
    gap: 8,
    height: 66,
    maxWidth: 520,
    padding: 8,
  },
}));

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  border: "2px solid",
  borderColor: theme.color.white,
  borderRadius: 32,
  color: theme.color.text,
  padding: "16px 24px 16px 24px",
  width: "100%",
  [theme.breakpoints.tablet]: {
    border: "none",
    flex: 1,
    width: "auto",
  },
}));

const WaitlistSubscription = () => {
  const [email, setEmail] = useState("");
  const [isProcess, setProcess] = useState(false);
  const disabled = useMemo(() => !Boolean(validateEmail(email)), [email]);
  const baseRoute = process.env.REACT_APP_API_URL || 'https://app-api.staging.pangealabs.xyz';

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setProcess(true);

    if (!disabled) {
      fetch(baseRoute + "/newsletter/subscription", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }).then((res) => {
        setProcess(false);
        if (res.status === 200) {
          setEmail("");
          toast.success(
            "Thank you for subscribing to our newsletter!  We can’t wait to share our latest news with you!",
            {}
          );
        } else {
          toast.error(
            "We apologize, but there was an issue with your newsletter subscription.\nPlease double-check the email address you provided and try again.",
            { style: { maxWidth: 600 } }
          );
        }
      });
    } else {
      setProcess(false);
    }
  };

  return (
    <Container justifyContent="flex-start">
      <StyledTextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Your email address"
      />
      <Button
        onClick={handleSubmit}
        disabled={disabled}
        height={50}
        borderRadius={100}
        variant="gradient"
      >
        {isProcess ? "Subscribing" : "Join our Waitlist"}
      </Button>
    </Container>
  );
};

export default WaitlistSubscription;
