import { Dispatch, ReactNode, SetStateAction } from "react";
import styled from "@emotion/styled";
import { ButtonProps, ComponentProps, TextProps } from "../types";
import { Box, Container, Text } from "..";

interface StepProps extends ButtonProps {
  active: boolean;
  body: ReactNode;
  index: number;
}

interface StepTextType extends TextProps {
  active: boolean;
}

interface StepsProps extends ComponentProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const StepButton = styled("button")<Partial<StepProps>>(
  ({ theme, active }) => ({
    alignSelf: "stretch",
    background: !active
      ? theme.color.transparent
      : theme.color.buttonStepBackground,
    borderRadius: 8,
    border: active ? "1px solid" : "none",
    borderColor: theme.color.buttonBorderOverlay,
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 0,
    padding: "24px 16px",
    transition: "all .2s ease-in-out",

    "&:hover": {
      [theme.breakpoints.desktop]: {
        background: theme.color.buttonStepBackgroundHover,
        transform: "scale(1.03)",
      },
      "&:active": {
        background: theme.color.buttonStepBackgroundActive,
        transform: "scale(0.98)",
      },
    },
  })
);

const NumberBox = styled(Box)<{ active: boolean }>(({ active, theme }) => ({
  width: 32,
  height: 32,
  border: "1px solid",
  color: theme.color.text,
  borderRadius: 58,
  borderColor: active ? theme.color.primary : theme.color.textSubtle,
}));

const StepText = styled(Text)<StepTextType>(({ theme, active }) => ({
  color: active ? theme.color.text : theme.color.textSubtle,
}));

const Step = ({ onClick, active = false, body, index }: StepProps) => {
  return (
    <StepButton onClick={onClick} active={active} variant="text">
      <Container justifyContent="flex-start" gap={32}>
        <NumberBox active={active}>
          <Text
            width={32}
            textAlign="center"
            alignSelf="center"
            variant="subtitle1"
          >
            {index}
          </Text>
        </NumberBox>
        <StepText variant="body" textAlign="left" active={active}>
          {body}
        </StepText>
      </Container>
    </StepButton>
  );
};

const stepProps = [
  {
    body: "Pangea integrates with Brand’s payment provider or commerce platform",
  },
  { body: "Brand announces the partnership" },
  {
    body: "NFT holders bind their wallets to a Web2 identity on our consumer site NotaWallet.xyz",
  },
  {
    body: "User logs into brand website or inputs their phone number at checkout",
  },
  { body: "Reward, perk, or reward is unlocked" },
];

const Steps = ({ activeStep, setActiveStep }: StepsProps) => {
  return (
    <Container
      flexDirection="column"
      justifyContent="flex-start"
      gap={16}
      paddingRight={20}
      maxWidth={735}
      style={{ boxSizing: "border-box" }}
    >
      {stepProps.map(({ body }, index) => (
        <Step
          key={index}
          index={index + 1}
          onClick={() => setActiveStep(index)}
          active={index === activeStep}
          body={body}
        />
      ))}
    </Container>
  );
};

export default Steps;
