import Box from "../Box";

const StripePricing = () => {
  return (
    <Box backgroundColor="rgb(22, 28, 36)" padding="100px 0" zIndex={1}>
      <stripe-pricing-table
        pricing-table-id="prctbl_1O54dgCkxUByGvqQPTS3173j"
        publishable-key="pk_live_51MwmcjCkxUByGvqQ2yy36Mhap0gXDK5ueBcVOw55nEBpe61MXG5urarXBxPlQGNsBq9EEMIzNxNZFHNBtz38pa3p00FZQt2qS0"
        style={{ width: "100%", zIndex: 100 }}
      />
    </Box>
  );
};

export default StripePricing;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
