import styled from "@emotion/styled";
import { ButtonProps } from "../types";
import { Button } from "@/components";

const GradientOutlineButton = styled(Button)<ButtonProps>(({ theme }) => {
  return {
    ...{
      width: 211,
      height: 56,
      border: 0,
      backgroundColor: theme.color.transparent,
      backgroundPosition: "center",
      backgroundSize: "211px 56px",
      backgroundImage:
        'url("/assets/components/gradient-outline-button-border.png")',

      [theme.breakpoints.tablet]: {
        width: 211,
      },
    },
  };
});

const FindOutHowButton = (props?: ButtonProps) => (
  <GradientOutlineButton variant="text" {...props}>
    Find Out How
  </GradientOutlineButton>
);

export default FindOutHowButton;
