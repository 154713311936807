import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { extractCustomComponentProps } from "@/utils/component";
import { ComponentProps } from "../types";
import { Container } from "..";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";
import { createRef, useEffect, useState } from "react";
import MobileMenu from "./MobileMenu";

const StyledNavbar = styled("nav")<ComponentProps>(
  ({ style, theme, ...props }) => ({
    ...{
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",

      justifyContent: "center",
      position: "fixed",
      top: 0,
      width: "100%",

      zIndex: 99,

      [theme.breakpoints.desktop]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      ...style,
    },
    ...extractCustomComponentProps(props),
  })
);

const Navbar = (props?: ComponentProps) => {
  const [opacity, setOpacity] = useState(0);
  const headerRef = createRef<HTMLElement>();
  const { pathname } = useLocation();

  useEffect(() => {
    const headerHeight = headerRef.current?.clientHeight;
    const range = 40;
    const offset = headerHeight ? headerHeight / 2 : 0;

    const didScrollPage = (e: Event) => {
      let calc = (window.scrollY - offset + range) / range;

      if (calc > 0.4) {
        calc = 0.4;
      } else if (calc < 0) {
        calc = 0;
      }

      setOpacity(calc);
    };

    window.addEventListener("scroll", didScrollPage);

    return () => {
      window.removeEventListener("keydown", didScrollPage);
    };
  }, [headerRef]);

  return (
    <StyledNavbar ref={headerRef} {...props}>
      <Container
        style={{
          position: "relative",
          height: 80,
          backdropFilter: "blur(4px)",
        }}
      >
        <Container
          style={{
            position: "absolute",
            backgroundColor: "#1E1E1E",
            opacity: opacity,
            height: 80,
            zIndex: 2,
          }}
        />
        <Container maxWidth={1440} zIndex={3}>
          <NavbarMobile pathname={pathname} />
          <NavbarDesktop pathname={pathname} />
          <MobileMenu pathname={window.location.pathname} />
        </Container>
      </Container>
    </StyledNavbar>
  );
};

export default Navbar;
