import styled from '@emotion/styled';
import {Box, Container, ContentContainer, Image, Text} from '@/components';
import GradientCard from './GradientCard';
import {useTheme} from '@emotion/react';

const PartnersSectionContainer = styled(Container)(({theme}) => ({
  padding: '0 20px 76px 20px',
  position: 'relative',
  '&:before': {
    backgroundImage: 'url(/assets/background/2-negative.png)',
    backgroundPosition: 'right -220px top 0px',
    backgroundRepeat: 'no-repeat',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',

    right: 0,
    top: 0,
  },
  [theme.breakpoints.tablet]: {
    '&:before': {
      backgroundPosition: 'right -120px top -160px',
    },
    padding: "0 20px 183px 20px",
  },
  [theme.breakpoints.desktop]: {
    '&:before': {
      backgroundPosition: 'right 0px top 210px',
    },
  },
}));

const InvestorsWrapper = styled(Container)(({theme}) => ({
  flexDirection: 'column',
  gap: 80,
  maxWidth: 1180,
  zIndex: 3,
  [theme.breakpoints.desktop]: {
    flexDirection: 'row',
    gap: 0,
    justifyContent: 'space-between',
  },
}));

const ResponsiveGradientCard = styled(GradientCard)(({theme}) => ({
  minHeight: 586,
  marginTop: 178,
  [theme.breakpoints.tablet]: {
    maxWidth: 758,
    marginTop: 340,
  },
  [theme.breakpoints.desktop]: {
    maxWidth: '100%',
  },
}));

const ResponsivePhoneImage = styled(Image)(({theme}) => ({
  bottom: 0,
  position: 'absolute',
  height: 'auto',
  width: 380,
  [theme.breakpoints.tablet]: {
    width: 463,
  },
}));

const ResponsiveInverstorsGradientCard = styled(GradientCard)(({theme}) => ({
  gap: 80,
  maxWidth: 758,
  marginTop: 120,
  [theme.breakpoints.desktop]: {
    marginTop: 250,
    maxWidth: 1180,
  },
}));

const PartnersSection = () => {
  const theme = useTheme();
  return (
    <PartnersSectionContainer>
      <ContentContainer flexDirection="column" gap={50}>
        <ResponsiveGradientCard>
          <Text maxWidth={500} textAlign="center" variant="header1" zIndex={3}>
            Web3 as simple as a phone number
          </Text>
          <Text
            marginTop={32}
            maxWidth={620}
            textAlign="center"
            variant="body2"
            zIndex={3}
            color={theme.color.textSubtle}
          >
            We've integrated proof-of-ownership technology into the largest Web2
            PoS systems and commerce platforms in the world. Our integration
            partners work with millions of businesses and serve billions of
            customers.
          </Text>
          <ResponsivePhoneImage src="/assets/components/gradientCard/phone.png"/>
          <Box
            position="absolute"
            justifyContent="center"
            bottom={-1}
            zIndex={1}
          >
            <Image
              minWidth={1828}
              src="/assets/background/ellipse-bottom.png"
            />
          </Box>
        </ResponsiveGradientCard>

        <ResponsiveInverstorsGradientCard>
          <Box flexDirection="column" gap={16} zIndex={3} alignItems="center">
            <Text variant="header1" zIndex={3} textAlign="center">
              Investors
            </Text>
            <Text
              maxWidth={560}
              textAlign="center"
              variant="body2"
              color={theme.color.textSubtle}
            >
              We're backed by the best names in technology, entertainment, and
              blockchain
            </Text>
          </Box>
          <InvestorsWrapper>
            <Image
              width={174}
              height={120}
              src="/assets/partners/metaversal.png"
            />
            <Image width={141} height={120} src="/assets/partners/theia.png"/>
            <Image width={243} height={70} src="/assets/partners/e11even.png"/>
          </InvestorsWrapper>
          <Box
            position="absolute"
            bottom={-1}
            zIndex={1}
            justifyContent="center"
          >
            <Image
              minWidth={1828}
              src="/assets/background/ellipse-bottom.png"
            />
          </Box>
        </ResponsiveInverstorsGradientCard>
      </ContentContainer>
    </PartnersSectionContainer>
  );
};

export default PartnersSection;
