import styled from "@emotion/styled";
import { TextFieldProps } from "../types";
import { extractCustomComponentProps } from "@/utils/component";

const StyledTextField = styled("input")<TextFieldProps>(
  ({ theme, style, ...props }) => ({
    ...{
      appearance: "none",
      background: "none",
      boxSizing: "border-box",
      outline: "none",
      width: "100%",
      border: "0px",
      "::placeholder": {
        fontSize: theme.typography.placeholder.fontSize,
        fontWeight: theme.typography.placeholder.fontWeight,
        lineHeight: theme.typography.placeholder.lineHeight,
      },
    },
    ...style,
    ...extractCustomComponentProps(props),
  })
);

const TextField = ({ className, placeholder, ...props }: TextFieldProps) => (
  <StyledTextField
    className={className}
    placeholder={placeholder}
    type="text"
    {...props}
  />
);

export default TextField;
