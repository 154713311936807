import {
  Box,
  Container,
  ContentContainer,
  Image,
  Link,
  Text,
} from "@/components";
import { useTheme } from "@emotion/react";
import {useMemo} from 'react';

const Footer = () => {
  const theme = useTheme();
  const year = useMemo(() => new Date().getFullYear(), [])

  return (
    <Container
      flexDirection="column"
      gap={48}
      id="#signup-input-section"
      padding="0 44px 70px"
      position="relative"
    >
      <Box
        bottom={0}
        display="flex"
        flex={1}
        justifyContent="center"
        position="absolute"
        zIndex={1}
      >
        <Image
          src="/assets/background/ellipse-bottom.svg"
          style={{ pointerEvents: "none" }}
        />
      </Box>
      <Image height={1} maxWidth={1180} src="/assets/icons/system/line.svg" />
      <ContentContainer
        flexDirection="column"
        gap={40}
        position="relative"
        zIndex={3}
      >
        <Image
          maxWidth={187}
          maxHeight={45}
          src="/assets/logo/pangea-labs.png"
        />
        <Text textAlign="center" variant="body" color={theme.color.textSubtle}>
          © {year} Pangea Labs LLC
        </Text>
        <Link to={`mailto:${process.env.REACT_APP_PANGEA_CONTACT_EMAIL}`}>
          <Text
            color={theme.color.textSubtle}
            textAlign="center"
            variant="body"
          >
            Contact us
          </Text>
        </Link>
      </ContentContainer>
    </Container>
  );
};

export default Footer;
