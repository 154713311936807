import { Box, Button } from "..";
import { ComponentProps } from "../types";

const navigateToApp = () =>
  window.location.assign("https://app.pangealabs.xyz");

const CTA: React.FC<ComponentProps> = (props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    marginLeft={32}
    {...props}
  >
    <Button
      variant="outlined"
      marginRight={16}
      size="large"
      onClick={navigateToApp}
      style={{
        whiteSpace: "nowrap",
      }}
    >
      Try it
    </Button>
    <Button variant="gradient" size="large" onClick={navigateToApp}>
      Login
    </Button>
  </Box>
);

export default CTA;
