import { Link } from "..";
import { URLS } from "@/utils/routes";

const Logo = () => (
  <Link style={{ maxHeight: 32 }} to={URLS.INDEX}>
    <img
      style={{
        objectFit: "contain",
        objectPosition: "center",
      }}
      src="/assets/logo/pangea.svg"
      alt="logo"
    />
  </Link>
);

export default Logo;
