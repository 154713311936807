import styled from "@emotion/styled";
import { Box, Image, Text } from "@/components";

const Hero = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  height: 600,
  paddingInline: 20,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 500,
  [theme.breakpoints.desktopS]: {
    maxWidth: 620,
  },
}));

const PricingHero = () => (
  <Box>
    <Box position="absolute" justifyContent="center">
      <Image minWidth={1200} src="/assets/background/ellipse-top.png" />
    </Box>
    <Image
      position="absolute"
      right={100}
      top={300}
      width={1000}
      height={1000}
      src="/images/blob.png"
    />
    <Hero zIndex={1} maxWidth={1440} margin="0 auto">
      <ContentContainer>
        <Text variant="header2" marginBottom={20}>
          Flexible plans tailored to your size and needs
        </Text>
        <Text variant="body" color="#898CA9">
          Integrating blockchain technology into your existing web platform has
          never been easier. Pangea Labs offers a solution that allows you to
          seamlessly add blockchain capabilities to your platform with minimal
          code implementation.
        </Text>
      </ContentContainer>
    </Hero>
  </Box>
);

export default PricingHero;
