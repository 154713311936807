import { useRef, useState, useEffect } from "react";
import { Parallax as ParallaxScroll } from "react-scroll-parallax";
import styled from "@emotion/styled";
import { Box, Container, Image } from "@/components";
import "./parallax.css";

const Background = styled(Container)({
  flexDirection: "column",
  justifyContent: "flex-start",
  position: "relative",
  height: "300vh",
});

const ParallaxSection = () => {
  const targetRef = useRef();

  const [targetElement, setElement] = useState();
  useEffect(() => {
    setElement(targetRef.current);
  }, []);
  return (
    <Background>
      <div
        // @ts-ignore
        ref={targetRef}
        style={{
          position: "absolute",
          bottom: 1200,
        }}
      ></div>
      <ParallaxScroll
        scale={[0.3, 1]}
        opacity={[1, 0.3]}
        translateY={[0, 800]}
        targetElement={targetElement}
      >
        <Box justifyContent="center">
          <Box maxWidth={1200}>
            <Image src="/assets/components/parallax/nota-wallet.png" />
          </Box>
        </Box>
      </ParallaxScroll>

      {/* <ParallaxScroll style={{ position: "absolute", bottom: 0 }}>
        <div>
          <Image src="/assets/components/parallax/mobile-phone.png" />
        </div>
      </ParallaxScroll>

      <ParallaxScroll style={{ position: "absolute", bottom: 0 }}>
        <div>
          <Text textAlign="center" variant="header1">
            Seamless reward claim
          </Text>
        </div>
      </ParallaxScroll>

      <ParallaxScroll style={{ position: "absolute", bottom: 0 }}>
        <div>
          <Text
            color={theme.color.textSubtle}
            maxWidth={580}
            textAlign="center"
            variant="body"
          >
            By integrating our proof-of-ownership technology into the top
            payment and commerce platforms we can create frictionless
            experiences native to these platforms that serve businesses and
            customers alike.
          </Text>
        </div>
      </ParallaxScroll>

      <ParallaxScroll style={{ position: "absolute", bottom: 0 }}>
        <div>
          <Button size="large">Explore Nota Wallet</Button>
        </div>
      </ParallaxScroll> */}
    </Background>
  );
};

export default ParallaxSection;
