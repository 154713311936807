import { useState } from "react";
import { Accordion, Box, Text } from "..";

const FAQ = [
  {
    q: "What are Claims?",
    a: "A claim is when a user, soulbound via Pangea Labs, claims the NFT Campaign Reward set by the business. A single user can have multiple wallets and emails. Every Pangea user is required to register with their phone number.",
  },
  {
    q: "Is the basic plan always free?",
    a: "Yes, the basic plan is free until you reach fifty claims per month.",
  },
  {
    q: "What happens when I exceed my plan limit, and are there any limitations with the Claims?",
    a: "If you exceed your monthly/yearly plan, your campaign will be paused until you upgrade the plan or until the new usage period. Claims are limited on a monthly basis.",
  },
  {
    q: "Do you offer any discounts?",
    a: "Yes, we do; we offer a 35% discount if you choose a yearly plan.",
  },
  {
    q: "What features are included in the enterprise plan?",
    a: "In the enterprise plan, you’ll have access to all the features of our platform, including Slack Connect, which enables seamless communication with our support team.",
  },
  {
    q: "How do I upgrade my plan?",
    a: "To upgrade your plan, log in to your account, go to the billing section, and select the plan you wish to upgrade to. Follow the prompts to complete the upgrade.",
  },
  {
    q: "Can I cancel my subscription at any time?",
    a: "Yes, you can cancel your subscription at any time. Your subscription will remain active until the end of the current billing cycle, after which you won’t be charged.",
  },
  {
    q: "Is my data secure with Pangea?",
    a: "Yes, we take data security seriously. We employ robust encryption protocols to protect your data and ensure that your information remains confidential and secure.",
  },
];

const PricingFAQ = () => {
  const [open, setOpen] = useState<number | null>(null);

  return (
    <Box padding="0px 20px" boxSizing="border-box">
      <Box
        flexDirection="column"
        alignItems="center"
        zIndex={2}
        margin="150px 0px"
        padding="0px 20px"
      >
        <Text variant="header2" marginBottom={32}>
          FAQs
        </Text>
        {FAQ.map(({ q, a }, i) => {
          const isOpen = open === i;
          return (
            <Accordion
              title={q}
              isOpen={isOpen}
              toggleOpen={() => setOpen(isOpen ? null : i)}
            >
              {a}
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default PricingFAQ;
