import { URLS } from "@/utils/routes";
import { ComponentProps } from "../types";
import { Link, Text } from "..";
import { useTheme } from "@emotion/react";
import { useDrawer } from "@/utils/DrawerContext";

interface NavbarItemProps extends ComponentProps {
  external?: boolean;
  name: string;
  path: URLS | string;
  pathname: string;
}

const NavbarItem = ({
  external = false,
  name,
  path,
  pathname,
}: NavbarItemProps) => {
  const theme = useTheme();
  const textColor = path === pathname ? theme.color.primary : theme.color.text;
  const target = external ? "_blank" : "";
  const { setDrawerOpen } = useDrawer();

  return (
    <Link
      to={path}
      target={target}
      style={{
        padding: "8px 0",
        textDecoration: "none",
      }}
      onClick={() => setDrawerOpen(false)}
    >
      <Text
        color={textColor}
        variant="button"
        style={{
          fontSize: 18,
        }}
      >
        {name}
      </Text>
    </Link>
  );
};

export default NavbarItem;
