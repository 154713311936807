import styled from "@emotion/styled";
import { ImageProps } from "../types";
import { extractCustomComponentProps } from "@/utils/component";

const StyledImage = styled("img")<ImageProps>(({ style, ...props }) => ({
  ...{
    width: "100%",
    height: "100%",
    ...style,
  },
  ...extractCustomComponentProps(props),
}));

const Image = (props?: ImageProps) => <StyledImage {...props} />;

export default Image;
