const body = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
};

const body1 = {
  fontSize: 18,
  fontWeight: 400,
  lineHeight: "27px",
};

const body2 = {
  fontSize: 24,
  fontWeight: 400,
  lineHeight: "32px",
};

const button = {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "16px",
};

const header1 = {
  fontSize: 50,
  fontWeight: 900,
  lineHeight: "65px",
};

const header2 = {
  fontSize: 42,
  fontWeight: 900,
  lineHeight: "54px",
};

const header2Regular = {
  fontSize: 42,
  fontWeight: 400,
  lineHeight: "54px",
};

const header3 = {
  fontSize: 30,
  fontWeight: 900,
  lineHeight: "39px",
};

const header4 = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: "36px",
};

const headerMobile1 = {
  fontSize: 50,
  fontWeight: 900,
  lineHeight: "65px",
};

const headerMobile2 = {
  fontSize: 40,
  fontWeight: 900,
  lineHeight: "52px",
};

const headerMobile3 = {
  fontSize: 32,
  fontWeight: 800,
  lineHeight: "41px",
};

const headerMobile4 = {
  fontSize: 28,
  fontWeight: 800,
  lineHeight: "36px",
};

const navItem = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "14px",
};

const navItemActive = {
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "14px",
};

const placeholder = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "-3%",
};

const subtitle1 = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
};

const textInput = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "21px",
};

const typography = {
  body,
  body1,
  body2,
  button,
  header1,
  header2,
  header2Regular,
  header3,
  header4,
  headerMobile1,
  headerMobile2,
  headerMobile3,
  headerMobile4,
  navItem,
  navItemActive,
  subtitle1,
  textInput,
  placeholder,
};

export default typography;
