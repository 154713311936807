import styled from "@emotion/styled";
import Container from "./Container";
import { extractCustomComponentProps } from "@/utils/component";
import { ComponentProps } from "../types";

const StyledContainer = styled(Container)<ComponentProps>(
  ({ style, ...props }) => {
    return {
      ...{
        [props.theme.breakpoints.desktop]: {
          boxSizing: "border-box",
          maxWidth: 1180,
        },
      },
      ...style,
      ...extractCustomComponentProps(props),
    };
  }
);

const ContentContainer = (props?: ComponentProps) => (
  <StyledContainer {...props} />
);
export default ContentContainer;
