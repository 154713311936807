import styled from "@emotion/styled";
import { Container, ContentContainer, Image, Text } from "@/components";
import Steps from "./Steps";
import { useState } from "react";

const Background = styled(Container)(({ theme }) => ({
  alignItems: "flex-start",
  boxSizing: "border-box",
  zIndex: 1,

  [theme.breakpoints.tablet]: {},
}));

const HowItWorksContainer = styled(ContentContainer)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "flex-start",
  maxWidth: 1727,
  paddingLeft: 20,
  paddingBottom: 200,
  gap: 48,
  zIndex: 2,
  [theme.breakpoints.tablet]: {
    paddingLeft: 0,
  },
}));

const StepsContainer = styled(ContentContainer)(({ theme }) => ({
  flexDirection: "column",
  gap: 48,
  justifyContent: "flex-start",
  [theme.breakpoints.desktop]: {
    flexDirection: "row-reverse",
  },
}));

const HowItWorksSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <Background>
      <HowItWorksContainer>
        <Text textAlign="center" maxWidth={720} variant="header2">
          How does it work?
        </Text>
        '
        <StepsContainer>
          <Image
            style={{
              maxHeight: 697,
              objectFit: "contain",
            }}
            src="/assets/components/howItWorks/1.png"
          />
          <Steps activeStep={activeStep} setActiveStep={setActiveStep} />
        </StepsContainer>
      </HowItWorksContainer>
    </Background>
  );
};

export default HowItWorksSection;
