import styled from "@emotion/styled";
import { ComponentProps } from "../types";
import { extractCustomComponentProps } from "@/utils/component";

const StyledContainer = styled("div")<ComponentProps>(({ style, ...props }) => {
  return {
    ...{
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    ...style,
    ...extractCustomComponentProps(props),
  };
});

const Container = (props?: ComponentProps) => <StyledContainer {...props} />;

export default Container;
