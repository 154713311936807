import { Box, Text, Image } from "@/components";

interface AccordionProps {
  isOpen: boolean;
  toggleOpen: () => void;
  title: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({
  isOpen,
  title,
  toggleOpen,
  children,
}: AccordionProps) => {
  return (
    <Box
      flexDirection="column"
      width="100%"
      maxWidth={1100}
      padding="24px 16px"
      style={{
        borderTop: "1px solid #e5e5e582",
        backgroundColor: isOpen ? "#ffffff10" : "",
        backdropFilter: isOpen ? "blur(8px)" : "",
        cursor: "pointer",
      }}
      onClick={toggleOpen}
    >
      <Box justifyContent="space-between">
        <Text variant="header4" style={{ fontWeight: 500 }}>
          {title}
        </Text>
        <Image
          height={32}
          width={32}
          marginLeft={16}
          src="/assets/icons/system/chevron-right.svg"
          style={{
            transform: !isOpen ? "rotate(90deg)" : "rotate(-90deg)",
            cursor: "pointer",
          }}
          zIndex={10}
        />
      </Box>
      {isOpen && (
        <Text
          variant="body1"
          marginTop={16}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Text>
      )}
    </Box>
  );
};

export default Accordion;
