import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@emotion/react";
import { DrawerContextProvider } from "@/utils/DrawerContext";
import RouteDefinition from "@/utils/RouteDefinition";
import theme from "@/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <DrawerContextProvider>
          <ParallaxProvider>
            <RouteDefinition />
            <Toaster
              toastOptions={{
                duration: 3500,
                style: {
                  backgroundColor: "#1A1B23",
                  color: "rgba(137, 140, 169, 1)",
                },
              }}
            />
          </ParallaxProvider>
        </DrawerContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
