import { ButtonSize, ButtonVariant, ComponentProps } from "@/components/types";
import { Theme } from "@emotion/react";

export function extractCustomComponentProps({
  alignItems,
  alignSelf,
  backgroundColor,
  borderRadius,
  bottom,
  boxSizing,
  color,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  gap,
  height,
  justifyContent,
  left,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  position,
  right,
  textAlign,
  top,
  width,
  zIndex,
}: ComponentProps): Omit<ComponentProps, "style" | "children"> {
  return {
    ...(alignItems && { alignItems }),
    ...(alignSelf && { alignSelf }),
    ...(backgroundColor && { backgroundColor }),
    ...(borderRadius && { borderRadius }),
    ...(bottom && { bottom }),
    ...(boxSizing && { boxSizing }),
    ...(color && { color }),
    ...(display && { display }),
    ...(flex && { flex }),
    ...(flexBasis && { flexBasis }),
    ...(flexDirection && { flexDirection }),
    ...(flexWrap && { flexWrap }),
    ...(gap && { gap }),
    ...(height && { height }),
    ...(justifyContent && { justifyContent }),
    ...(left && { left }),
    ...(margin && { margin }),
    ...(marginBottom && { marginBottom }),
    ...(marginLeft && { marginLeft }),
    ...(marginRight && { marginRight }),
    ...(marginTop && { marginTop }),
    ...(maxHeight && { maxHeight }),
    ...(maxWidth && { maxWidth }),
    ...(minHeight && { minHeight }),
    ...(minWidth && { minWidth }),
    ...(overflow && { overflow }),
    ...(padding && { padding }),
    ...(paddingBottom && { paddingBottom }),
    ...(paddingLeft && { paddingLeft }),
    ...(paddingRight && { paddingRight }),
    ...(paddingTop && { paddingTop }),
    ...(position && { position }),
    ...(right && { right }),
    ...(textAlign && { textAlign }),
    ...(top && { top }),
    ...(width && { width }),
    ...(zIndex && { zIndex }),
  };
}

export function getPaddingsBySize(variant: ButtonSize) {
  switch (variant) {
    case "large":
      return {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
      };
    case "small":
      return {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
      };
    case "none":
      return {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      };
    default:
      return {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 24,
        paddingRight: 24,
      };
  }
}

export function getColorStylesByVariant(variant: ButtonVariant, theme: Theme) {
  switch (variant) {
    case "gradient":
      return {
        background: theme.color.buttonGradient,
        borderColor: theme.color.buttonBorderOverlay,
      };
    case "outlined":
      return {
        backgroundColor: theme.color.transparent,
        borderColor: theme.color.text,
      };
    case "text":
      return {
        backgroundColor: theme.color.transparent,
        borderColor: theme.color.transparent,
      };
    case "disabled":
      return {
        transform: "none",
        cursor: "not-allowed",
        backgroundColor: theme.color.buttonDisabled,
        borderColor: theme.color.buttonBorderOverlay,
      };
    default:
      return {
        backgroundColor: theme.color.primary,
      };
  }
}

export function getHoverAndActiveStyles(variant: ButtonVariant, theme: Theme) {
  switch (variant) {
    case "gradient":
      return [{}, {}];
    case "outlined":
      return [
        {
          backgroundColor: theme.color.buttonTransparentActive,
        },
        {
          backgroundColor: theme.color.buttonTransparentHover,
        },
      ];
    case "text":
      return [
        {
          backgroundColor: theme.color.buttonTransparentActive,
        },
        {
          backgroundColor: theme.color.buttonTransparentHover,
        },
      ];
    case "gradientOutlined":
      return [
        {
          backgroundColor: theme.color.buttonTransparentActive,
        },
        {
          backgroundColor: theme.color.buttonTransparentHover,
        },
      ];
    case "disabled":
      return [
        {
          backgroundColor: theme.color.buttonDisabled,
        },
        {
          backgroundColor: theme.color.buttonDisabled,
        },
      ];
    default:
      return [
        {
          backgroundColor: theme.color.buttonPrimaryActive,
        },
        {
          backgroundColor: theme.color.buttonPrimaryHover,
        },
      ];
  }
}
