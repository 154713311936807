import { Container, Footer, Navbar } from "@/components";
import {
  LandingSection,
  PartnersSection,
} from "@/components/comingSoon";
import { useTheme } from "@emotion/react";

const ComingSoon = () => {
  const theme = useTheme();
  return (
    <>
      <Navbar />
      <Container
        backgroundColor={theme.color.background}
        flexDirection="column"
        overflow="hidden"
      >
        <LandingSection />
        <PartnersSection />
        <Footer />
      </Container>
    </>
  );
};

export default ComingSoon;
