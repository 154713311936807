import styled from "@emotion/styled";
import { Box, Container, ContentContainer, Image, Text } from "@/components";
import { useTheme } from "@emotion/react";
import GetStartedTodayButton from "./GetStartedTodayButton";

const Background = styled(Container)(({ theme }) => ({
  alignItems: "flex-start",
  boxSizing: "border-box",
  height: 1130,
  overflow: "hidden",
  position: "relative",
  zIndex: 1,
  "&:before": {
    backgroundImage: "url(/assets/background/2.png)",
    backgroundPosition: "left -690px top -100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: 1130,
    bottom: 0,
    content: '""',
    left: 0,
    position: "absolute",
    opacity: 0.3,

    right: 0,
    top: 0,
  },
  [theme.breakpoints.tablet]: {
    "&:before": {
      backgroundSize: 1070,
      backgroundPosition: "left -590px top -40px",
    },
    height: 940,
    paddingLeft: 45,
    paddingRight: 45,
  },
  [theme.breakpoints.desktop]: {
    height: 1054,
    "&:before": {
      backgroundPosition: "left -520px top 40px",
      backgroundSize: 1070,
    },
  },
  [theme.breakpoints.desktopXl]: {
    height: 1174,
    "&:before": {
      backgroundPosition: "left -520px top 0px",
      backgroundSize: 1123,
    },
  },
}));

const EthereumIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  minHeight: 900,
  marginLeft: 230,
  position: "absolute",
  top: 140,

  zIndex: 1,
  [theme.breakpoints.tablet]: {
    marginLeft: 0,
    top: 47,
  },
  [theme.breakpoints.desktop]: {
    top: 120,
  },
}));

const StarIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
  minHeight: 900,
  position: "absolute",
  top: 460,
  marginLeft: 240,
  zIndex: 1,
  [theme.breakpoints.tablet]: {
    marginLeft: 470,
    top: -30,
  },
  [theme.breakpoints.desktop]: {
    top: 80,
    marginLeft: 1070,
  },
  [theme.breakpoints.desktopXl]: {
    marginLeft: 1270,
    top: 80,
  },
}));

const BlockchainAdoptionSection = () => {
  const theme = useTheme();
  return (
    <Background>
      <EthereumIconContainer>
        <Image width={150} height={150} src="/assets/background/ethereum.png" />
      </EthereumIconContainer>
      <StarIconContainer>
        <Image width={61} height={60} src="/assets/background/star.svg" />
      </StarIconContainer>
      <ContentContainer
        alignSelf="center"
        flexDirection="column"
        gap={56}
        position="relative"
        paddingLeft={20}
        paddingRight={20}
        zIndex={2}
      >
        <Container height="auto" flexDirection="column" gap={32}>
          <Text textAlign="center" maxWidth={720} variant="header1">
            Simplify Blockchain Adoption with Pangea Labs
          </Text>
          <Text
            color={theme.color.textSubtle}
            maxWidth={590}
            paddingLeft={15}
            paddingRight={15}
            textAlign="center"
            variant="body"
          >
            By integrating our proof-of-ownership technology into the top
            payment and commerce platforms we can create frictionless
            experiences native to these platforms that serve businesses and
            customers alike.
          </Text>
        </Container>
        <GetStartedTodayButton />
      </ContentContainer>
    </Background>
  );
};

export default BlockchainAdoptionSection;
