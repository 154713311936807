import styled from "@emotion/styled";
import { ComponentProps } from "../types";
import { Container, Link, Logo, Text } from "..";
import theme from "@/theme";
import CTA from "./CTA";

interface NavbarDesktopProps extends ComponentProps {
  pathname: string;
}

const DesktopContainer = styled(Container)<ComponentProps>(({ theme }) => ({
  display: "none",
  [theme.breakpoints.desktopS]: {
    display: "flex",
    padding: 24,
  },
  [theme.breakpoints.desktop]: {
    display: "flex",
    padding: 20,
  },
  [theme.breakpoints.navbarPaddingBreakpoint]: {
    padding: 0,
  },
}));

const NavbarDesktop = ({ pathname }: NavbarDesktopProps) => {
  return (
    <DesktopContainer>
      <Container flex={1} justifyContent="flex-start">
        <Logo />
      </Container>
      <Container flex={3} gap={32}>
        {/* <NavbarItem name="Home" path={URLS.HOME} pathname={pathname} />
        <NavbarItem name="Pricing" path={URLS.PRICING} pathname={pathname} />
        <NavbarItem
          external
          name="Nota Wallet"
          path={URLS.NOTA_WALLET}
          pathname={pathname}
        />
        <NavbarItem external name="Docs" path={URLS.DOCS} pathname={pathname} /> */}
      </Container>

      <Container flex={1} gap={16} justifyContent="flex-end">
        <Link to={`/pricing`}>
          <Text color={theme.color.text} variant="button">
            Pricing
          </Text>
        </Link>
        <Link to={`mailto:${process.env.REACT_APP_PANGEA_CONTACT_EMAIL}`}>
          <Text
            color={theme.color.text}
            variant="button"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Contact us
          </Text>
        </Link>
        {/* <Button minWidth={100} variant="outlined">
          Try it
        </Button>
        <Button variant="gradient">Login</Button> */}
        <CTA />
      </Container>
    </DesktopContainer>
  );
};

export default NavbarDesktop;
