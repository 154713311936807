import styled from "@emotion/styled";
import { Box, Container, Image, Text } from "@/components";
import GradientCard from "./GradientCard";
import { useTheme } from "@emotion/react";

const Background = styled(Container)(({ theme }) => ({
  alignItems: "flex-start",
  overflow: "hidden",
  paddingBottom: 3,
  paddingLeft: 20,
  paddingRight: 20,
  position: "relative",
  "&:before": {
    backgroundImage: "url(/assets/background/1-negative.png)",
    backgroundPosition: "left -140px top 200px",
    backgroundRepeat: "no-repeat",
    bottom: 0,
    content: '""',
    left: 0,
    position: "absolute",

    right: 0,
    top: 0,
  },
  [theme.breakpoints.desktop]: {
    "&:before": {
      backgroundPosition: "left 0px top 150px",
    },
  },
}));

const ResponsiveMainHeader = styled(Text)(({ theme }) => ({
  color: "white",
  fontSize: 36,
  lineHeight: "46px",
  maxWidth: 490,
  textAlign: "center",
  [theme.breakpoints.desktop]: {
    fontSize: 50,
    lineHeight: "65px",
    maxWidth: 900,
  },
}));

const ResponsiveHeader = styled(Text)(({ theme }) => ({
  marginTop: 150,
  textAlign: "center",
  [theme.breakpoints.tablet]: {
    marginTop: 270,
  },
}));

const ResponsiveGradientCard = styled(GradientCard)(({ theme }) => ({
  gap: 48,
  maxWidth: 758,
  justifyContent: "flex-start",
  [theme.breakpoints.desktop]: {
    minHeight: 774,
  },
}));

const PartnersWrapper = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  gap: 24,
  [theme.breakpoints.desktop]: {
    flexDirection: "row",
  },
}));

const PartnersImageWrapper = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  gap: 32,
  maxWidth: 417,
  zIndex: 3,
  [theme.breakpoints.desktop]: {
    gap: 80,
  },
}));

const ContentContainer = styled(Container)({
  alignItems: "center",
  flexDirection: "column",
  gap: 48,
  justifyContent: "flex-start",
  maxWidth: 1180,
  paddingTop: 270,
  zIndex: 10,
});

const LandingSection = () => {
  const theme = useTheme();

  return (
    <Background>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        position="absolute"
        top={0}
        zIndex={1}
      >
        <Image minWidth={1200} src="/assets/background/ellipse-top.png" />
      </Box>
      <ContentContainer>
        <Image
          maxWidth={498}
          maxHeight={120}
          src="/assets/logo/pangea-labs.png"
        />
        <Box width="auto" flexDirection="column" gap={48}>
          <Text variant="header2Regular" textAlign="center">
            Powering Web2.5
          </Text>
          <ResponsiveMainHeader variant="header1">
            We're building thousands of digital bridges connecting Web2 with
            Web3
          </ResponsiveMainHeader>
        </Box>
        <Box flexDirection="column" gap={16} alignItems="center">
          <ResponsiveHeader variant="header2">
            Integration Partners
          </ResponsiveHeader>
          <Text
            color={theme.color.textSubtle}
            maxWidth={550}
            textAlign="center"
            variant="body2"
          >
            Our integrations partners are used by millions of businesses and
            serve billions of customers
          </Text>
        </Box>
        <PartnersWrapper>
          <ResponsiveGradientCard>
            <Text variant="headerMobile3" zIndex={3}>
              Payment
            </Text>
            <PartnersImageWrapper>
              <Box flexDirection="column" flexBasis="50%">
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/stripe.svg" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/mindbody.png" />
                </Box>
                {/* <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/`verifone`.png" />
                </Box> */}
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/revel.png" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/dutchie.png" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/flowhub.png" />
                </Box>
              </Box>
              <Box flexDirection="column" flexBasis="50%">
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/klarna.png" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/toast.png" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/square.png" />
                </Box>
                <Box maxHeight={100}>
                  <Image src="/assets/partners/payment/clover.png" />
                </Box>
              </Box>
            </PartnersImageWrapper>
            <Box
              position="absolute"
              top={-1}
              zIndex={1}
              justifyContent="center"
            >
              <Image minWidth={1828} src="/assets/background/ellipse-top.png" />
            </Box>
          </ResponsiveGradientCard>
          <ResponsiveGradientCard
            gap={48}
            maxWidth={758}
            justifyContent="flex-start"
          >
            <Text variant="headerMobile3" zIndex={3}>
              Commerce
            </Text>
            <PartnersImageWrapper
              flexDirection="row"
              gap={32}
              maxWidth={417}
              zIndex={3}
            >
              <Box flexDirection="column" flexBasis="50%">
                <Image src="/assets/partners/commerce/bigcommerce.png" />
                <Image src="/assets/partners/commerce/magento.png" />
                <Image src="/assets/partners/commerce/shopify.png" />
                <Image src="/assets/partners/commerce/woocommerce.png" />
                <Image src="/assets/partners/commerce/squarespace.png" />
              </Box>
              <Box flexDirection="column" flexBasis="50%">
                <Image src="/assets/partners/commerce/webflow.png" />
                <Image src="/assets/partners/commerce/sellfy.png" />
                <Image src="/assets/partners/commerce/wix.png" />
                <Image src="/assets/partners/commerce/wordpress.png" />
                <Image src="/assets/partners/commerce/weebly.png" />
              </Box>
            </PartnersImageWrapper>
            <Box
              position="absolute"
              top={-1}
              zIndex={1}
              justifyContent="center"
            >
              <Image minWidth={1828} src="/assets/background/ellipse-top.png" />
            </Box>
          </ResponsiveGradientCard>
        </PartnersWrapper>
      </ContentContainer>
    </Background>
  );
};

export default LandingSection;
