import { Container, Footer, Navbar, StripePricing } from "@/components";
import { PricingFAQ, PricingHero } from "@/components/Pricing";
import { useTheme } from "@emotion/react";

const Pricing = () => {
  const theme = useTheme();
  return (
    <>
      <Navbar />
      <Container
        backgroundColor={theme.color.background}
        flexDirection="column"
        overflow="hidden"
      >
        <PricingHero />
        <StripePricing />
        <PricingFAQ />
        <Footer />
      </Container>
    </>
  );
};

export default Pricing;
