import styled from "@emotion/styled";
import { extractCustomComponentProps } from "@/utils/component";
import { ComponentProps } from "../types";

const Card = styled("div")<ComponentProps>(({ style, theme, ...props }) => ({
  ...{
    alignItems: "center",
    background: theme.color.cardGradientBackground,
    borderRadius: 20,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    padding: "48px 20px",
    position: "relative",
    width: "100%",
    [theme.breakpoints.tablet]: {
      borderRadius: 80,
      padding: 48,
    },
    ...style,
  },
  ...extractCustomComponentProps(props),
}));

const GradientCard = (props: ComponentProps) => <Card {...props} />;

export default GradientCard;
