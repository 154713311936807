import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface DrawerContextType {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}
interface DrawerContextProps {
  children?: ReactNode;
}

export const DrawerContext = createContext<DrawerContextType | null>(null);
export const DrawerContextProvider: FC<DrawerContextProps> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const value = { drawerOpen, setDrawerOpen };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error(
      "useDrawerContext must be used within an DrawerContextProvider"
    );
  }
  return context;
};
