import styled from "@emotion/styled";
import {
  Box,
  Container,
  ContentContainer as ContentContainerBase,
  Image,
  Text,
} from "@/components";
import { useTheme } from "@emotion/react";
import WaitlistSubscription from "@/components/home/WaitlistSubscription";
import PillarsCard from "./PillarsCard";

const Background = styled(Container)(({ theme }) => ({
  alignItems: "flex-start",
  minHeight: 800,
  overflow: "hidden",
  paddingBottom: 3,
  position: "relative",
  "&:before": {
    backgroundImage: "url(/assets/background/1.png)",
    backgroundPosition: "right -316px top -50px",
    backgroundRepeat: "no-repeat",
    backgroundSize: 1090,
    bottom: 0,
    content: '""',
    left: 0,
    position: "absolute",
    opacity: 0.3,
    right: 0,
    top: 0,
  },
  [theme.breakpoints.tablet]: {
    "&:before": {
      backgroundPosition: "right -160px top 20px",
      opacity: 0.4,
      backgroundSize: 1100,
    },
    paddingLeft: 45,
    paddingRight: 45,
  },
  [theme.breakpoints.desktop]: {
    "&:before": {
      backgroundPosition: "right -160px top -25px",
      backgroundSize: 1200,
      opacity: 0.8,
    },
  },
}));

const WaitlistSubscriptionContainer = styled(ContentContainerBase)(
  ({ theme }) => ({
    alignItems: "flex-start",
    boxSizing: "border-box",
    flexDirection: "column",
    gap: 20,
    justifyContent: "flex-start",
    paddingBottom: 210,
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 220,
    position: "relative",
    [theme.breakpoints.tablet]: {
      paddingBottom: 293,
      paddingLeft: 45,
      paddingRight: 45,
      paddingTop: 320,
    },
    [theme.breakpoints.desktop]: {
      paddingLeft: 0,
      paddingRight: 0,
      height: 668,
    },
  })
);

const PillarsSectionContainer = styled(ContentContainerBase)(({ theme }) => ({
  boxSizing: "border-box",
  flexDirection: "column",
  gap: 24,
  position: "relative",
  paddingLeft: 22,
  paddingRight: 22,

  [theme.breakpoints.tablet]: {
    alignItems: "stretch",
    flexDirection: "row",
  },
  [theme.breakpoints.desktop]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const LandingSection = () => {
  const theme = useTheme();
  return (
    <Background>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        position="absolute"
        top={0}
        zIndex={1}
      >
        <Image
          minWidth={580}
          maxWidth={828}
          src="/assets/background/ellipse-top.svg"
        />
      </Box>
      <Container flexDirection="column" justifyContent="flex-start">
        <WaitlistSubscriptionContainer>
          <Text variant="header2">Web3 simple as a phone number</Text>
          <Text maxWidth={605} color={theme.color.textCaption} variant="body">
            Integrating blockchain technology into your existing web platform
            has never been easier. Pangea Labs offers a solution that allows you
            to seamlessly add blockchain capabilities to your platform with
            minimal code implementation.
          </Text>
          <WaitlistSubscription />
        </WaitlistSubscriptionContainer>
        <PillarsSectionContainer>
          <PillarsCard
            icon="/assets/icons/custom/time.png"
            title="Real-Time Proof of Ownership"
            body="Our technology offers real-time proof of ownership, giving your platform the security and transparency it needs to thrive"
          />
          <PillarsCard
            icon="/assets/icons/custom/integration.png"
            title="Multichain Support for Seamless Integration"
            body="Works on any EVM-based blockchain, making it easy to integrate into your existing web ecosystem"
          />
          <PillarsCard
            icon="/assets/icons/custom/shield.png"
            title="Secure NFT Verification on the Customer Side"
            body="Our solution offers secure NFT verification on the customer side, making it easy for consumers to access and share ownership proof via social login, phone number, or email"
          />
        </PillarsSectionContainer>
      </Container>
    </Background>
  );
};

export default LandingSection;
