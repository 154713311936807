import { ComponentProps } from "../types";
import { Card, Image, Text } from "..";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

interface PillarsCardProps extends ComponentProps {
  body: string;
  icon: string;
  title: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  flex: 1,
  gap: 16,
  justifyContent: "flex-start",
  maxWidth: 335,
  height: 388,
  transition: "all .2s ease-in-out",
  "&:hover": {
    transform: "scale(1.015)",
  },
  [theme.breakpoints.tablet]: {
    maxHeight: 650,
    height: "auto",
  },
  [theme.breakpoints.desktop]: {
    justifyContent: "center",
    maxWidth: 380,
    height: 388,
  },
}));

const PillarsCard = ({ body, icon, title }: PillarsCardProps) => {
  const theme = useTheme();
  return (
    <StyledCard>
      <Image width={80} height={80} src="/assets/icons/custom/time.png" />
      <Text textAlign="center" variant="headerMobile4">
        {title}
      </Text>
      <Text textAlign="center" variant="body" color={theme.color.textSubtle}>
        {body}
      </Text>
    </StyledCard>
  );
};

export default PillarsCard;
