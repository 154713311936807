import { Link as RouterLink, LinkProps } from "react-router-dom";

const Link = (props: LinkProps) => (
  <RouterLink
    style={{ padding: 0, margin: 0, textDecoration: "none" }}
    {...props}
  />
);

export default Link;
