import styled from "@emotion/styled";
import { ComponentProps } from "../types";
import { extractCustomComponentProps } from "@/utils/component";

const StyledContainer = styled("div")<ComponentProps>(
  ({ style, theme, ...props }) => {
    return {
      ...{
        alignItems: "center",
        backgroundColor: theme.color.cardBackground,
        borderRadius: 25,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "32px 24px",
      },
      ...style,
      ...extractCustomComponentProps(props),
    };
  }
);

const Card = (props?: ComponentProps) => <StyledContainer {...props} />;

export default Card;
