import styled from "@emotion/styled";
import {
  extractCustomComponentProps,
  getColorStylesByVariant,
  getHoverAndActiveStyles,
  getPaddingsBySize,
} from "@/utils/component";
import { ButtonProps } from "../types";

const StyledButton = styled("button")<ButtonProps>(
  ({ disabled = false, size = "medium", theme, variant = "contained" }) => {
    const paddings = getPaddingsBySize(size);
    const colorScheme = getColorStylesByVariant(variant, theme);
    const [backgroundActive, backgroundHover] = getHoverAndActiveStyles(
      variant,
      theme
    );

    return ({ style, ...props }) => ({
      ...{
        ...theme.typography.button,
        color: theme.color.text,
        margin: 0,
        padding: 0,
        width: "100%",
        borderRadius: "10px",
        border: "1px solid",
        boxSizing: "border-box",
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "all .2s ease-in-out",
        ":hover": {
          [theme.breakpoints.desktop]: {
            transform: disabled ? "" : "scale(1.03)",
          },
          ...backgroundHover,
        },
        ":active": {
          transform: "scale(0.98)",
          ...backgroundActive,
        },
      },
      ...paddings,
      ...colorScheme,
      [theme.breakpoints.tablet]: {
        width: "auto",
      },
      ...extractCustomComponentProps(props),
    });
  }
);

const Button = ({ variant = "contained", ...props }: ButtonProps) => (
  <StyledButton variant={variant} {...props} />
);

export default Button;
