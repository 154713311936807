import styled from "@emotion/styled";
import { ComponentProps } from "../types";
import { Button, Container, Logo, Image } from "..";
import { useDrawer } from "@/utils/DrawerContext";

interface NavbarMobileProps extends ComponentProps {
  pathname: string;
}

const MobileContainer = styled(Container)<ComponentProps>(({ theme }) => ({
  justifyContent: "space-between",
  padding: 20,
  [theme.breakpoints.desktopS]: {
    height: "auto",
    display: "none",
  },
}));

const NavbarMobile = (_props: NavbarMobileProps) => {
  const { drawerOpen, setDrawerOpen } = useDrawer();

  return (
    <MobileContainer padding={24}>
      <Container flex={1} justifyContent="flex-start" zIndex={2}>
        <Logo />
      </Container>
      <Container flex={1} justifyContent="flex-end" zIndex={2}>
        <Button
          borderRadius="50%"
          height={36}
          onClick={() => setDrawerOpen(!drawerOpen)}
          size="none"
          variant="text"
          width={36}
        >
          <Image height={20} width={18} src="/assets/icons/system/menu.svg" />
        </Button>
      </Container>
    </MobileContainer>
  );
};

export default NavbarMobile;
