import styled from "@emotion/styled";
import { ComponentProps } from "../types";
import { extractCustomComponentProps } from "@/utils/component";

const StyledBox = styled("div")<ComponentProps>(({ style, ...props }) => ({
  ...{
    display: "flex",
    width: "100%",
    ...style,
  },
  ...extractCustomComponentProps(props),
}));

const Box = (props?: ComponentProps & React.HTMLAttributes<HTMLDivElement>) => (
  <StyledBox {...props} />
);

export default Box;
